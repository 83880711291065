
import { mapGetters } from 'vuex'
import BagIcon from '@/components/BagIcon'
import CloseIcon from '@/assets/svg/heroicons/x-circle.svg?inline'
import ImageIcon from '@/assets/svg/heroicons/image.svg?inline'

export default {
  components: {
    BagIcon,
    CloseIcon,
    ImageIcon
  },
  data () {
    return {
      isBag: this.$route.path.includes('bag')
    }
  },
  props: {
    color: {
      type: String,
      required: false,
      default: 'white'
    }
  },
  computed: {
    ...mapGetters({
      cartItems: 'cart/getItems',
      cartTotalItems: 'cart/getNumberOfItems',
      cartUUID: 'cart/getUUID'
    })
  },
  mounted () {
    if (!this.$store.state.cart.init) {
      this.$store.dispatch('cart/fetchCartItems')
    }
  },
  methods: {
    removeCartItem (uuid) {
      this.$store.dispatch('cart/removeCartItem', uuid)
    },
    prepareForCheckOut () {
      this.$router.push(this.$i18nPath(`/checkout/${this.cartUUID}`))
    },
    async logoutNow () {
      if (this.$intercom) {
        this.$intercom.shutdown()
        this.$intercom.update({})
      }
      await this.$store.dispatch('auth/logout')

      await this.$router.push(
        this.$i18nPath('/')
      )
    }
  }
}
