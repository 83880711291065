
import { mapGetters } from "vuex";

export default {
  computed: {
    isUnsubscribe() {
      return this.$route.path.startsWith(this.$i18nPath("/unsubscribe"));
    },
    isStorePage() {
      return this.$route.path.startsWith(this.$i18nPath("/stores"));
    },
    isAboutUsPage() {
      return this.$route.path.startsWith(this.$i18nPath("/about-us"));
    },
    isHomePage() {
      return this.$route.name == "country";
    },
    isCuratorVisible() {
      return this.isHomePage && this.curatorEnable;
    },
    ...mapGetters({
      defaultLocales: "locales/getDefaultLocales",
    }),
  },
  data() {
    return {
      curatorEnable: false,
    };
  },
  mounted() {
    this.$loadIubendaCookieConsent();
    this.$loadGTM();
    if (process.browser) {
      window.onscroll = () => {
        if (document.documentElement.scrollTop > 10) {
          if (!this.curatorEnable) {
            this.curatorEnable = true;
          }
        }
      };
    }
    setTimeout(() => {
      if (!this.isStorePage && this.$loadIntercom) {
        this.$loadIntercom();
      }
    }, 1500);
  },
  head() {
    const baseAppUrl = this.$config.APP_URL;
    const styles = [];
    // eslint-disable-next-line
    let metaLinks = [
      { rel: "alternate", href: `${baseAppUrl}`, hreflang: "x-default" },
      {
        rel: "canonical",
        href: `${baseAppUrl}${this.$route.path.substring(1)}`,
      },
    ];

    // Add alternate urls
    this.defaultLocales
      .filter((site) => site.slug === this.$store.state.country)
      .forEach((site) => {
        site.urls.forEach((item) => {
          metaLinks.push({
            rel: "alternate",
            href: item.url,
            hreflang: item.locale_code,
          });
        });
      });

    return {
      titleTemplate: "%s | Keeway",
      link: metaLinks,
      style: styles,
      htmlAttrs: {
        lang: this.$store.state.locale,
        dir: this.$store.state.isRtl ? "rtl" : "ltr",
      },
    };
  },
};
