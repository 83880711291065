
export default {
  data() {
    return {
      email: null,
      accept_terms: false,
      sending: false,
      errors: {},
      VueHcaptcha: null,
    };
  },
  mounted() {
    if (process.browser) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (!this.VueHcaptcha) {
              this.VueHcaptcha = () => import("@hcaptcha/vue-hcaptcha");
            }
          }
        });
      });

      observer.observe(document.getElementById("newsletter"));
    }
  },
  methods: {
    onVerify(token, ekey) {
      this.$axios
        .$post("/api/front/subscriber", {
          email: this.email,
          accept_terms: this.accept_terms,
          "h-captcha-response": token,
        })
        .then((response) => {
          this.$toast.success(this.$t("common.newsletter_thanks"));
          this.email = null;
          this.accept_terms = false;
          this.sending = false;

          window._iub.cons_instructions.push([
            "submit",
            {
              writeOnLocalStorage: false,
              form: {
                selector: document.getElementById("newsletter_form"),
              },
              consent: {
                legal_notices: [
                  {
                    identifier: "privacy_policy",
                  },
                  {
                    identifier: "cookie_policy",
                  },
                  {
                    identifier: "term",
                  },
                ],
              },
            },
            {
              success(response) {
                // console.log(response)
              },
              error(response) {
                console.warning(response);
              },
            },
          ]);
        })
        .catch((error) => {
          console.error(error);
          this.errors = error.response.data.errors;
          this.sending = false;
          this.accept_terms = false;
        });
    },
    onExpire() {
      // this.$toast.error('Captcha expired')
      console.log("[vue-htcapcha] Expired token");
      this.sending = false;
    },
    onError() {
      console.log("[vue-htcapcha] Failed to load hcaptcha");
      this.sending = false;
    },
    onSubmit() {
      if (this.sending) {
        return;
      }
      this.sending = true;
      this.errors = {};

      this.$refs.invisibleHcaptcha.execute();
    },
  },
};
