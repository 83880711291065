
import { mapGetters } from "vuex";
import CloseIcon from "@/assets/svg/heroicons/x.svg?inline";
import AngleDown from "@/assets/svg/heroicons/angle-down.svg?inline";

export default {
  components: {
    MobileMenuIcon: () =>
      import("@/assets/svg/heroicons/mobile-menu.svg?inline"),
    CloseIcon,
    AngleDown,
  },
  data() {
    return {
      visible: false,
      isProductPage: false,
      isAboutUsPage: false,
      isMenuShow: false,
      isStorePage: false,
      drawer: null,
      scrollPosition: 0,
    };
  },
  computed: {
    ...mapGetters({
      products: "getProducts",
      categories: "getCategories",
      currentSite: "getCurrentSite",
      currentSiteSettings: "getCurrentSiteSettings",
    }),
  },
  watch: {
    $route() {
      if (window.location.href.includes("/products/")) {
        this.isProductPage = true;
      } else {
        this.isProductPage = false;
      }
      if (window.location.href.includes("/stores")) {
        this.isStorePage = true;
      } else {
        this.isStorePage = false;
      }
      if (window.location.href.includes("/about-us")) {
        this.isAboutUsPage = true;
      } else {
        this.isAboutUsPage = false;
      }
    },
  },

  mounted() {
    this.isProductPage = window.location.href.includes("/products/");
    this.isAboutUsPage = window.location.href.includes("/about-us/");
    this.isStorePage = window.location.href.includes("/stores");
    const MmenuLight = require("mmenu-light/esm/core/index").default;

    const menu = new MmenuLight(
      document.querySelector("#mmenu"),
      "(max-width: 1024px)"
    );

    /* eslint-disable-next-line */
    const navigator = menu.navigation({
      title: this.$t("common.menu"),
    });
    this.drawer = menu.offcanvas({
      position: this.$store.state.isRtl ? "right" : "left",
    });

    window.addEventListener("scroll", this.updateScroll);

    var elements = document.querySelectorAll(".mm-ocd__backdrop");

    elements[0].addEventListener("touchend", this.onToggleMenu);
    elements[0].addEventListener("mouseup", this.onToggleMenu);

    this.visible = true;
  },
  beforeDestroy() {
    this.removeListener();
  },
  methods: {
    onToggleMenu() {
      this.isMenuShow = false;
    },
    isActive(active) {
      if (active == "home" && this.$route.name == "country") {
        return true;
      }
      return this.$route.path.includes(active);
    },
    removeListener() {
      var elements = document.querySelectorAll(".mm-ocd__backdrop");

      elements[0].removeEventListener("touchend", this.onToggleMenu);
      elements[0].removeEventListener("mouseup", this.onToggleMenu);
    },
    onClickLink(route) {
      this.isMenuShow = false;
      this.drawer.close();
      this.$router.push(route);
    },
    open() {
      this.isMenuShow = true;
      this.drawer.open();
    },
    close() {
      this.isMenuShow = false;
      this.drawer.close();
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
};
