
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      isProductPage: false,
      isAboutUsPage: false,
      isStorePage: false,
      scrollPosition: 0,
      color: "white",
    };
  },

  computed: {
    ...mapGetters({
      products: "getProducts",
      categories: "getCategories",
      currentSite: "getCurrentSite",
      currentSiteSettings: "getCurrentSiteSettings",
    }),
    navClasses() {
      const classes = [];
      /* } else if (removeLastDash(this.$i18nPath(this.$route.path)) === removeLastDash(this.$i18nPath('/'))) {
        classes.push('bg-opacity-20')
      } */
      if (this.$route.path.startsWith(this.$i18nPath("/products/"))) {
        classes.push("md:mb-[-110px] bg-opacity-0");
      }
      if (
        this.scrollPosition >= 80 &&
        (this.isProductPage || this.isAboutUsPage)
      ) {
        classes.push("bg-white scrollable-header ");
      } else if (this.scrollPosition >= 80 || this.isStorePage) {
        classes.push("bg-white scrollable-header scrollable-header-shadow");
      } else {
        classes.push("bg-gradient-to-b	from-[#0000005c] to-[#5c5c5c00]");
      }

      return classes;
    },
    newTextClass() {
      if (
        this.$store.state.locale === "el" ||
        this.$store.state.locale === "ar"
      ) {
        return "xl:text-xs text-[11px] ";
      } else {
        return "xl:text-sm text-[11px] ";
      }
    },
  },

  watch: {
    $route() {
      if (window.location.href.includes("/products/")) {
        this.isProductPage = true;
      } else {
        this.isProductPage = false;
      }
      if (window.location.href.includes("/stores")) {
        this.isStorePage = true;
      } else {
        this.isStorePage = false;
      }
      if (window.location.href.includes("/about-us")) {
        this.isAboutUsPage = true;
      } else {
        this.isAboutUsPage = false;
      }
    },
  },
  mounted() {
    this.isProductPage = window.location.href.includes("/products/");
    this.isStorePage = window.location.href.includes("/stores");
    this.isAboutUsPage = window.location.href.includes("/about-us");
    window.addEventListener("scroll", this.updateScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.updateScroll);
  },

  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },

    changeColor(color) {
      this.color = color;
    },
  },
};
