
import CloseIcon from '~/assets/svg/heroicons/x-circle-fill.svg?inline'

export default {
  components: {
    CloseIcon
  },
  props: {
    errors: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    hasErrors () {
      return Object.keys(this.errors).length > 0
    }
  }
}
