import { render, staticRenderFns } from "./CuratorWidget.vue?vue&type=template&id=307b2970&"
import script from "./CuratorWidget.vue?vue&type=script&lang=js&"
export * from "./CuratorWidget.vue?vue&type=script&lang=js&"
import style0 from "./CuratorWidget.vue?vue&type=style&index=0&id=307b2970&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports