
import { mapGetters } from "vuex";
import NuxtSSRScreenSize from "nuxt-ssr-screen-size";
import "vue-collapsible-component/lib/vue-collapsible.css";
import Collapsible from "vue-collapsible-component";
import CloseIcon from "@/assets/svg/heroicons/x.svg?inline";
import AngleUp from "@/assets/svg/heroicons/angle-up.svg?inline";
import AngleDown from "@/assets/svg/heroicons/angle-down.svg?inline";
import KeewayGroupLogo from "./KeewayGroupLogo.vue";
// import Graybg from '~/assets/images/general/Graybg.svg?inline'

export default {
  components: {
    Collapsible,
    CloseIcon,
    AngleUp,
    AngleDown,
    Slogan: () => import("~/assets/svg/slogan.svg?inline"),
    FooterLogo: () => import("~/assets/svg/footer-logo.svg?inline"),
    LetsRideTogether: () =>
      import("~/assets/svg/lets-ride-together.svg?inline"),
    KeewayGroupLogo,
    // Graybg
  },
  mixins: [NuxtSSRScreenSize.NuxtSSRScreenSizeMixin],
  data(d) {
    return {
      browserWidth: 0,
      group_companies: [],
      year: new Date().getFullYear(),
      intercom: typeof d.$intercom !== "undefined" ? d.$intercom : null,
    };
  },
  computed: {
    ...mapGetters({
      products: "getProducts",
      sites: "getSites",
      country: "getCountry",
      locales: "locales/getDefaultLocales",
      currentSiteSettings: "getCurrentSiteSettings",
      currentSite: "getCurrentSite",
      features: "getFeatures",
    }),
    textClass() {
      return this.$store.state.locale === "el"
        ? "text-[17px] 2xl:text-[20px] font-bold"
        : "text-[30px]";
    },
    countryName() {
      const country = this.locales.find((x) => x.slug === this.country);
      return country ? country.country.name : "";
    },
    checkSocialExist() {
      let data = [
        "facebook",
        "instagram",
        "linkedin",
        "twitter",
        "youtube",
        "tiktok",
      ];
      return data.find((_v) => this.currentSiteSettings.socials[_v]);
    },
  },
  mounted() {
    if (this.features) {
      const findData = this.features.find((_v) => _v.slug == "group-companies");
      if (findData && findData.settings) {
        this.group_companies = findData.settings;
      }
    }
    this.browserWidth = this.$vssWidth;
    window.addEventListener("resize", this.windowResized);
    if (process.browser) {
      const collapsible = document.getElementsByClassName(
        "Collapsible__trigger"
      );
      if (collapsible && collapsible[0]) {
        collapsible[0].setAttribute("aria-label", "keeway info");
      }
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.windowResized);
  },
  methods: {
    windowResized() {
      this.browserWidth = this.$vssWidth;
    },
  },
};
