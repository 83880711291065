import { render, staticRenderFns } from "./Newsletter.vue?vue&type=template&id=8fa3f76c&"
import script from "./Newsletter.vue?vue&type=script&lang=js&"
export * from "./Newsletter.vue?vue&type=script&lang=js&"
import style0 from "./Newsletter.vue?vue&type=style&index=0&id=8fa3f76c&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Btn: require('/usr/src/app/components/Btn.vue').default,ErrorBag: require('/usr/src/app/components/ErrorBag.vue').default})
