
export default {
  props: {
    backgroundClass: {
      type: String,
      required: false,
      default: "text-white group-hover:text-[#101010]",
    },
    iconClass: {
      type: String,
      required: false,
      default: "text-primary group-hover:text-white",
    },
  },
};
